import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "addressBar", "browser", "iframe", "toggle", "deviceInput"];

  initialize() {
  }

  connect() {
    console.log("playground");
    let tgts = this.inputTargets
      setTimeout(() => {
      tgts.forEach((input) => {
        console.log(`SETTING INPUT VALUE ${input.defaultValue}`);
        input.value = input.defaultValue;
      })}, 500);
  }

  frameLoad(e) {
    console.log('frame load!');
    console.log(e);
  }

  toggle(e) {
    e.stopPropagation();
    console.log("toggle!");
    this.deviceInputTarget.value = this.deviceInputTarget.value == "desktop" ? "mobile" : "desktop";
    this.browserTarget.classList.toggle("md:max-w-sm");
    this.browserTarget.classList.toggle("max-w-[3072px]")
    this.toggleTarget.classList.toggle("translate-x-0")
    this.toggleTarget.classList.toggle("translate-x-[28px]")
    // Submit the playform
    if(this.element.dataset.url.length > 0) {
      console.log("submitting!");
      Rails.fire(document.querySelector("form"), 'submit');
    }
  }

  switchPage(e) {
    e.preventDefault();
    e.currentTarget.dataset.page
  }


  go(e) {
    if(e.keyCode == 13) {
      e.stopPropagation();
      // Check if the url domain is different
      if(new URL(this.element.dataset.url).origin != new URL(e.currentTarget.value).origin) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("url", e.currentTarget.value);
        window.location.search = searchParams.toString();
        // window.location.href = 
      } else {
        this.goTo(`${this.element.dataset.host}/proxy?url=${e.currentTarget.value}`);
      }
    } 
  }

  goTo(url) {
    this.iframeTarget.src = url;
  }

  get playFormController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("form"), "playForm");
  }
}