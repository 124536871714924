import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { getMetaValue } from "../utils/helpers";
export default class extends Controller {
  static targets = ["button", "disable", "modal", "pageInput", "iframe", "pageSwitcher", "matchBanner"]

  initialize() {
  }

  connect() {
    window.addEventListener("message", this.messageHandler.bind(this));
    if(["pending", "processing"].includes(this.element.dataset.status)) this.pollPage(null);
  }

  updateCurrentPageUrl(e) {
    let params = new URLSearchParams(this.iframeTarget.contentWindow.location.search);
    params.get('url');
    // const data = new URLSearchParams();
    // for (const pair of new FormData(this.element)) {
    //     data.append(pair[0], pair[1]);
    // }
    // fetch(this.element["action"], {
    //     method: 'post',
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //       'X-CSRF-Token': getMetaValue("csrf-token")
    //     },
    //     credentials: 'same-origin',
    //     body: data,
    // })
  }
  selfNavigate(e) {
    this.displayButtonLoading(e);
    const fr = this.iframeTarget;
    const mod = this.modalTarget;
    fetch(this.element.dataset.selfNavigateAction, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then((json) => {
      // Turbo.renderStreamMessage(html);
      if(!fr.src?.length) sr.src = json.url;
      mod.remove();
    });
  }
  fallbackPage(e) {
    console.log("fallback page");
    this.displayButtonLoading(e);
    const poll = this.pollPage.bind(this);
    fetch(this.element.dataset.fallbackPageAction, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    }).then (response => response.text())
    .then(html => {
      poll(e);
      Turbo.renderStreamMessage(html);
    });
  }

  fallbackSession(e) {
    console.log("fallback session");
    this.displayButtonLoading(e);
    const poll = this.pollPage.bind(this);
    fetch(this.element.dataset.fallbackSessionAction, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    }).then (response => response.text())
    .then(html => {
      poll(e);
      Turbo.renderStreamMessage(html);
    });
  }

  switchPage(e) {
    e.preventDefault();
    // e.stopPropogation();
    console.log("switch page")
    console.log(this.element["action"]);
    this.pageInputTarget.value = e.currentTarget.dataset.page;
    const data = new URLSearchParams();
    for (const pair of new FormData(this.element)) {
        data.append(pair[0], pair[1]);
    }
    fetch(this.element["action"], {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': getMetaValue("csrf-token")
        },
        credentials: 'same-origin',
        body: data,
    })
    .then(response => response.text())
    .then(html => {
      console.log("render");
      Turbo.renderStreamMessage(html);
      this.iframeTarget?.contentWindow?.location?.reload();
      let forms = document.querySelectorAll("form.simple_form.edit_play_session");
      if(forms.length > 1) forms[forms.length - 1].remove();
    });
  }

  submit(e) {
    if(this.hasDisableTarget) {
      this.disableTargets.forEach((t) => {
        t.disabled = true;
      });
    }
    // 
    if(this.hasModalTarget) {
      this.element.submit();
      console.log("has modal target");
      this.pollPage(e);
    } else {
      Rails.fire(this.element, 'submit');
    }
  }

  pollPage(e) {
    //TODO Poll the current page
    let pollCount = 0;
    let pollAction = this.element.dataset.pollPageAction;
    let playCtrl = this.playgroundController;
    let modalTgts = this.modalTargets;
    function doPoll() {
      console.log("do poll");
      if(pollCount >= 30) {
        clearInterval(myInterval);
        // Timeout the scraper
      }
      pollCount ++;
      fetch(pollAction, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': getMetaValue("csrf-token")
        },
        credentials: 'same-origin'
      }).then(response => response.json())
        .then((json) => {
        if(json.status == "success") {
          console.log("SUCCESS!");
          clearInterval(myInterval);
          // TODO Update the browser
          playCtrl.goTo(json.url);
          console.log(document.querySelectorAll("[data-target='play-form.modal']"));
          document.querySelectorAll("[data-target='play-form.modal']").forEach((tgt) => {
            tgt.remove();
          });
        }
      });
      pollCount ++;
    }
    var myInterval = setInterval(doPoll, 2000);

    // var myInterval = setInterval(everyTime, 1000);
    //   while(pollCount < 10) {
    //     setTimeout(() => {
        
    //     }, 1000);
    //   }
  }

  messageHandler(e) {
    if(e.origin != window.location.origin) return;
    try{
      let data = JSON.parse(e.data);
      if(data.action == "saveHtml") {
        //Save the html to the session page
        let formData = new FormData();
        formData.append('session_page[page_html]', data.html)
        fetch(this.element.dataset.savePageAction, {
          method: 'PUT',
          body: formData,
          headers: {
            'X-CSRF-Token': getMetaValue("csrf-token")
          },
          credentials: 'same-origin'
        }).then(response => {
          console.log(response);
        });
      } else if(data.action == "showCurrentPage") {
        if(data.matchedPaths) this.matchBannerTarget.remove();
        const url = new URL(window.location.href);
        url.searchParams.set('page', data.value );
        window.history.pushState(null, '', url.toString());

        this.pageSwitcherTarget.outerHTML = unescape(data.html);
      } else if (data.action == "toggle_feature") {
        if(this.element.dataset.toggleFeatureAction == undefined) return;
        let formData = new FormData();
        formData.append('featureset_id', data.featureset_id)
        formData.append('feature_id', data.feature_id)
        fetch(this.element.dataset.toggleFeatureAction, {
          method: 'PUT',
          body: formData,
          headers: {
            'X-CSRF-Token': getMetaValue("csrf-token")
          },
          credentials: 'same-origin'
        }).then (response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
        });
      }
    } catch(e) {
      console.log(e);
    }
  }

  displayLoading(e) {
    if(this.hasButtonTarget) {
      this.buttonTarget.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" class="animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12a9 9 0 1 1-6.219-8.56"></path></svg>';
    }
  }

  displayButtonLoading(e) {
    e.currentTarget.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" class="animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12a9 9 0 1 1-6.219-8.56"></path></svg>';
  }

  get playgroundController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("main"), "playground");
  }
}